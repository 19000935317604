import type { ImageType } from '@/core/features/image-v2/image-type';

import React from 'react';

import ActivityTileDesktopV1 from '@/features/activity-tiles/activity-tiles-v1-desktop/activity-tile-desktop';

import styles from './activity-tiles-v1-desktop.module.scss';

type TileColumnsConfig = {
    className: string;
    end?: number;
    start: number;
    twoInTotal?: boolean;
};

export type ActivityTilesV1DesktopProps = {
    tiles: {
        image: ImageType;
        linkUrl: string;
        scrollToTarget: null | string;
        subtitle?: null | string;
        title: string;
    }[];
};

export default function ActivityTilesV1Desktop({ tiles }: ActivityTilesV1DesktopProps) {
    const config = tileColumnsConfig[tiles.length];
    if (!config) {
        return null;
    }
    return (
        <div className={styles.imageContainer}>
            {config.map(({ className, end, start }, index) => (
                <React.Fragment key={`${className}-${index}`}>
                    {tiles.slice(start, end).map(({ image, linkUrl, scrollToTarget, subtitle, title }, index) => (
                        <div
                            className={className}
                            key={`${linkUrl}-${index}`}
                        >
                            <ActivityTileDesktopV1
                                height={tiles.length === 1 ? 370 : 200}
                                image={image}
                                isNewActivity={title === 'Radfahren'}
                                layoutWidth={33}
                                linkUrl={linkUrl}
                                scrollToTarget={scrollToTarget}
                                subtitle={subtitle}
                                title={title}
                            />
                        </div>
                    ))}
                </React.Fragment>
            ))}
        </div>
    );
}

const tileColumnsConfig: Record<number, TileColumnsConfig[]> = {
    1: [{ className: styles.fullWidth, start: 0, twoInTotal: false }],
    2: [{ className: styles.fourImages, start: 0 }],
    3: [{ className: styles.fourImages, start: 0 }],
    4: [{ className: styles.fourImages, start: 0 }],
    5: [{ className: styles.fiveImages, start: 0 }],
    6: [{ className: styles.threeImages, start: 0 }],
    7: [
        { className: styles.threeImages, end: 3, start: 0 },
        { className: styles.fourImages, start: 3 },
    ],
    8: [{ className: styles.fourImages, start: 0 }],
    9: [
        { className: styles.fourImages, end: 4, start: 0 },
        { className: styles.fiveImages, start: 4 },
    ],
    10: [{ className: styles.fiveImages, start: 0 }],
    11: [
        { className: styles.fourImages, end: 4, start: 0 },
        { className: styles.threeImages, end: 7, start: 4 },
        { className: styles.fourImages, start: 7 },
    ],
    12: [{ className: styles.fourImages, start: 0 }],
    13: [
        { className: styles.fourImages, end: 4, start: 0 },
        { className: styles.fiveImages, end: 9, start: 4 },
        { className: styles.fourImages, start: 9 },
    ],
    14: [
        { className: styles.fiveImages, end: 5, start: 0 },
        { className: styles.fourImages, end: 9, start: 5 },
        { className: styles.fiveImages, start: 9 },
    ],
    15: [{ className: styles.fiveImages, start: 0 }],
};
