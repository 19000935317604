import { Badge } from '@/core/features';
import color from '@/core/features/styles/color';

import styles from './activity-new-activity-badge.module.css';

export default function ActivityNewActivityBadge() {
    return (
        <div className={`absolute top-0 flex-center bold ${styles.highlightBadge}`}>
            <Badge
                badge={{
                    backgroundColor: color('mySin'),
                    htmlText: 'Neu',
                    outlineColor: null,
                    textColor: color('white'),
                }}
                fontSize={14}
                padding={'padding-left-15 padding-right-15'}
            />
        </div>
    );
}
