import type { ImageType } from '@/core/features/image-v2/image-type';
import type { ResponseImageLayoutWidth } from '@/core/features/image-v2/responsive-image';

import { HtmlTextWrapper, Link, ResponsiveImage } from '@/core/features';
import { bemModule } from '@/core/utils/bem-classname';
import ActivityNewActivityBadge from '@/features/activity-tiles/activity-highlight-badge/activity-new-activity-badge';
import { trackActivityTilesClick } from '@/features/activity-tiles/activity-tiles-tracking';

import styles from './activity-tile-desktop.module.scss';

const bem = bemModule(styles);

type ActivityTileDesktopProps = {
    height: 200 | 245 | 370 | 500;
    image: ImageType;
    isFullWidth?: boolean;
    isNewActivity: boolean;
    layoutWidth: ResponseImageLayoutWidth;
    linkUrl: string;
    scrollToTarget: null | string;
    subtitle?: null | string;
    title: string;
};

export default function ActivityTileDesktopV1({
    height,
    image,
    isFullWidth,
    isNewActivity = false,
    layoutWidth,
    linkUrl,
    scrollToTarget,
    subtitle,
    title,
}: ActivityTileDesktopProps) {
    return (
        <Link
            fullWidth={true}
            href={scrollToTarget || linkUrl}
            linkType={scrollToTarget ? 'scroll' : 'internal'}
            onClick={() => trackActivityTilesClick(title)}
            qaId={scrollToTarget ? 'qa-search-page-scroll-to-component-desktop' : 'qa-search-page-link-desktop'}
        >
            <div className={'relative'}>
                <div className={styles.activityTile}>
                    <ResponsiveImage
                        alt={image.imageAlt}
                        className={styles.imageZoom}
                        height={height}
                        layoutWidth={layoutWidth}
                        preload={true}
                        url={image.imageUrl}
                    />
                    <div className={`${isFullWidth ? 'flex-center' : ''} ${bem(styles.titleWrapper, { isFullWidth })}`}>
                        <div className={'flex gap-10 align-center'}>
                            <HtmlTextWrapper
                                className={`${bem(styles.title, { isFullWidth })}`}
                                htmlText={title}
                            />
                        </div>
                        {subtitle && <div className={`${bem(styles.subtitle, { isFullWidth })}`}>{subtitle}</div>}
                    </div>
                </div>
                {isNewActivity && <ActivityNewActivityBadge />}
            </div>
        </Link>
    );
}
