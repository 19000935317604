/* eslint-disable no-console */
/* eslint-disable fp/no-mutation */
import { isObject } from '@/core/utils/object';

/**
 * Shamelessly stolen and adapted from https://www.npmjs.com/package/@jahed/bem
 *
 * Changes:
 * - added Error handling for classes that are missing
 * - support reverse lookup of classes, allowing to pass the scss module class object name
 *   instead of a string for autocompletion support
 * - change bem modifier seperator to `--<modifier name>-<modifier value>`
 */
type ClassName = string;
type Modifiers = { [key: string]: boolean | null | number | string | undefined };
type CSSModule = { [key: string]: ClassName };
type ArrayOrVarArgs<T> = T[] | T[][];
type BEM = (elementName: ClassName, modifiers?: Modifiers) => ClassName;

export const classnames = (...classNames: ArrayOrVarArgs<ClassName | Record<ClassName, boolean> | null | undefined>) =>
    join(...evalConditions(...classNames));

export const bemModule = (styles: CSSModule = {}): BEM => {
    const elementNameToKey = Object.entries(styles).reduce<Record<string, string>>((prev, [key, value]) => {
        prev[value] = key;
        return prev;
    }, {});

    return (elementName, modifiers): string => {
        const key = elementNameToKey[elementName];
        if (!key) {
            console.error(`BEM className Error: could not locate key for elementName ${elementName}`);
            return '';
        }
        return join(generateClassNames(key, modifiers).map((className: string) => mapClassNames(styles, className)));
    };
};

const evalConditions = (...classNames: ArrayOrVarArgs<ClassName | Record<ClassName, boolean> | null | undefined>) => {
    return classNames.map((className) => {
        if (isObject(className)) {
            return Object.entries(className).reduce((acc, [key, value]) => {
                return !!value ? `${acc} ${key}` : acc;
            }, '');
        }
        return className;
    });
};

const mapClassNames = (styles: CSSModule, className: string) => {
    const moduleClass = styles[className];
    if (typeof moduleClass !== 'string') {
        const classConfigMessage = `\nClass config: ${JSON.stringify(styles, null, 2)}`;
        if (className === undefined) {
            console.error(
                `BEM className Error: READ CLOSELY! The given className is undefined. This is likely because this class does not include any styles, e.g. it maybe only has modifiers, but no styles on its own. Please add styles to the class or remove it. ${classConfigMessage}`,
            );
            return;
        }
        console.error(`BEM className Error: ${className} was not found${classConfigMessage}`);
    }

    return moduleClass;
};

const join = (...classNames: ArrayOrVarArgs<ClassName | false | null | undefined>): ClassName =>
    ((Array.isArray(classNames[0]) ? classNames[0] : classNames) as ClassName[]).filter((i) => i).join(' ');

const generateClassNames = (elementName: ClassName, modifiers: Modifiers = {}): ClassName[] => {
    const classes = Object.keys(modifiers)
        .filter((key) => !!modifiers[key] && modifiers[key] !== 'default')
        .flatMap((modifierType) => {
            if (elementName === undefined) {
                console.info(`\x1B[31m ❌ elementName is undefined for modifier ${modifierType}`);
                return [];
            }
            const modifierValue = modifiers[modifierType];
            return typeof modifierValue === 'boolean'
                ? `${elementName}--${modifierType}`
                : `${elementName}--${modifierType}-${modifierValue}`;
        });
    classes.unshift(elementName);
    return classes;
};
